<template>
  <v-container style="max-width: 800px">
    <v-card>
      <v-card-title>
        <h4 class="text-center w-full">
          TERMOS DE USO E ACEITAÇÃO DOS TERMOS E CONDIÇÕES
        </h4>
      </v-card-title>
      <v-card-text class="px-4">
        <h5>
          CONTRATO DE TERMOS DE USO DO APLICATIVO ATLETIC.ME ACEITAÇÃO DOS
          TERMOS E CONDIÇÕES
        </h5>
        <p>
          Ao utilizar o aplicativo TicketMe (&quot;Aplicativo&quot;), você
          concorda com os termos e condições deste Contrato de Termos de Uso
          (&quot;Contrato&quot;). Caso não concorde com os termos e condições
          deste Contrato, você não deverá utilizar o Aplicativo.
        </p>
        <h5>DESCRIÇÃO DO APLICATIVO</h5>
        <p>
          TicketMe é um aplicativo gerenciador de eventos que facilita a
          organização, produção e venda de ingressos para festas e eventos. O
          aplicativo oferece aos usuários a possibilidade de criar, promover,
          vender e gerenciar eventos e ingressos de maneira simplificada.
        </p>
        <h5>CONTA DE USUÁRIO</h5>
        <p>
          Para acessar e utilizar os recursos do Aplicativo, você deverá criar
          uma conta de usuário. Ao criar uma conta, você concorda em fornecer
          informações verdadeiras, precisas e completas. Você é responsável por
          manter a confidencialidade das suas informações de login e senha e por
          todas as atividades que ocorram sob a sua conta.
        </p>
        <h5>USO ADEQUADO</h5>
        <p>
          Você concorda em utilizar o Aplicativo apenas para fins lícitos e em
          conformidade com todas as leis e regulamentações aplicáveis. Você não
          deverá utilizar o Aplicativo de maneira que possa causar danos,
          desabilitar, sobrecarregar ou prejudicar o Aplicativo ou interferir no
          uso e aproveitamento do mesmo por parte de terceiros.
        </p>
        <h5>PROPRIEDADE INTELECTUAL</h5>
        <p>
          O Aplicativo e seu conteúdo, incluindo, sem limitação, textos,
          gráficos, logotipos, ícones, imagens, vídeos, interfaces e código, são
          de propriedade exclusiva da TicketMe e protegidos por direitos
          autorais, marcas registradas e outras leis de propriedade intelectual
          aplicáveis. É proibido o uso, a reprodução, a distribuição ou a
          modificação do Aplicativo e de seu conteúdo sem a autorização prévia e
          expressa da TicketMe.
        </p>
        <h5>RESPONSABILIDADES DO USUÁRIO</h5>
        <p>
          Ao utilizar o Aplicativo, você assume total responsabilidade pelo
          conteúdo que publicar e pelas informações que fornecer. Você concorda
          em indenizar, defender e isentar a TicketMe e seus afiliados de
          qualquer reivindicação, responsabilidade, dano ou custo, incluindo
          honorários advocatícios razoáveis, decorrentes do seu uso do
          Aplicativo, do seu descumprimento deste Contrato ou da violação de
          direitos de terceiros.
        </p>
        <h5>LIMITAÇÃO DE RESPONSABILIDADE</h5>
        <p>
          A TicketMe não será responsável por qualquer dano direto, indireto,
          incidental, especial, consequente ou punitivo, incluindo, sem
          limitação, perda de lucros, perda de dados ou interrupção de negócios,
          decorrentes do uso ou da incapacidade de usar o Aplicativo.
        </p>
        <h5>RESCISÃO</h5>
        <p>
          A TicketMe reserva-se o direito de rescindir o seu acesso e uso do
          Aplicativo, a seu exclusivo critério, sem aviso prévio, por qualquer
          motivo ou sem motivo.
        </p>
        <h5>MODIFICAÇÕES</h5>
        <p>
          A TicketMe reserva-se o direito de modificar, a seu exclusivo
          critério, ostermos e condições deste Contrato a qualquer momento. As
          alterações entrarão em vigor imediatamente após a publicação das
          alterações no Aplicativo. Ao continuar a utilizar o Aplicativo após a
          entrada em vigor de tais alterações, você concorda em cumprir os
          termos e condições conforme alterados.
        </p>
        <h5>POLÍTICA DE PRIVACIDADE</h5>
        <p>
          Ao utilizar o Aplicativo, você concorda com a coleta, uso e divulgação
          de suas informações pessoais conforme estabelecido na Política de
          Privacidade da TicketMe, que pode ser acessada através do Aplicativo e
          incorporada a este Contrato por referência.
        </p>
        <h5>POLÍTICA DE REEMBOLSO E CANCELAMENTO</h5>
        <p>
          Os organizadores de eventos são responsáveis pela definição de suas
          políticas de reembolso e cancelamento de ingressos. A TicketMe não é
          responsável por quaisquer reembolsos ou cancelamentos de ingressos,
          exceto conforme exigido por lei. É responsabilidade do usuário
          verificar as políticas de reembolso e cancelamento do evento antes de
          realizar a compra dos ingressos.
        </p>
        <h5>LEGISLAÇÃO APLICÁVEL E FORO</h5>
        <p>
          Este Contrato será regido e interpretado de acordo com as leis do
          Brasil, sem consideração a conflitos de princípios legais. Você
          concorda em submeter-se à jurisdição exclusiva dos tribunais do Brasil
          para a resolução de quaisquer disputas decorrentes deste Contrato ou
          do uso do Aplicativo.
        </p>
        <h5>DISPOSIÇÕES GERAIS</h5>
        <p>
          Se qualquer disposição deste Contrato for considerada inválida ou
          inexequível, as demais disposições permanecerão em pleno vigor e
          efeito. A falha da TicketMe em exercer ou executar qualquer direito ou
          disposição deste Contrato não constituirá uma renúncia de tal direito
          ou disposição. Este Contrato constitui o entendimento completo e
          exclusivo entre você e a TicketMe em relação ao Aplicativo e substitui
          todas as comunicações anteriores, propostas e acordos, escritos ou
          orais, entre as partes em relação ao objeto deste Contrato.
        </p>
        <h5>CONTATO</h5>
        <p>
          Se você tiver alguma dúvida ou preocupação em relação a este Contrato
          ou ao Aplicativo, entre em contato com a TicketMe por meio dos canais
          de atendimento disponíveis no Aplicativo.
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
